<template>
  <div>
    <div
      v-if="route.path !== '/invoices' && route.path !== '/wallet' && route.name !== 'confirm'"
      class="fixed -bottom-2 left-0 w-full h-[82px] grid md:hidden place-items-center px-1 z-50"
    >
      <div class="z-10 flex items-end justify-between w-full">
        <div class="w-[40%] flex items-center justify-between">
          <div :class="activeLink === '/dashboard' ? 'text-primary-500' : 'text-blue-dark-300'" class="w-1/2 py-4 pr-4" @click="changeRoute('/dashboard')">
            <div class="flex flex-col items-center w-10 pl-2 m-auto gap-y-1">
              <BaseIcon icon="dashboard" :fill="activeLink === '/dashboard'" />
              <span class="text-xs font-bold">داشبورد</span>
            </div>
          </div>
          <div :class="activeLink === '/cards' ? 'text-primary-500' : 'text-blue-dark-300'" class="w-1/2 py-4 pr-4" @click="changeRoute('/cards')">
            <div class="flex flex-col items-center w-10 pl-4 m-auto gap-y-1">
              <BaseIcon size="24" icon="receipt" :fill="activeLink === '/cards'" />
              <span class="text-xs font-bold">سفارش‌ها</span>
            </div>
          </div>
        </div>
        <div :class="activeLink === '/store' ? 'text-primary-500' : 'text-blue-dark-300'" class="px-4 py-4" @click="changeRoute('/store')">
          <div
            class="absolute left-1/2 -translate-x-1/2 top-[-24px] z-10 w-12 h-12 grid place-items-center rounded-full"
            :class="activeLink === '/store' ? 'bg-primary-500' : 'bg-white border-[1px]'"
          >
            <BaseIcon
              icon="store"
              class="!text-[32px]"
              :class="activeLink === '/store' ? 'text-white' : 'text-blue-dark-400'"
            />
          </div>
          <div class="flex flex-col h-full">
            <span class="text-xs font-bold">فروشگاه</span>
          </div>
        </div>

        <div class="w-[40%] flex items-center justify-between">
          <div :class="activeLink === '/orders' ? 'text-primary-500' : 'text-blue-dark-300'" class="w-1/2 px-4 py-4 " @click="changeRoute('/orders')">
            <div class="flex flex-col items-center pr-6 m-auto gap-y-1">
              <BaseIcon icon="local-shipping" :fill="activeLink === '/orders'" />
              <span class="text-xs font-bold">بارها</span>
            </div>
          </div>
          <div class="flex flex-col items-center w-1/2 px-4 py-4 m-auto gap-y-1 text-blue-dark-300" @click="moreItemsMenu = true">
            <div v-if="authStore.userInfo.avatar_url" :style="{ 'background-image': 'url(' + authStore.userInfo.avatar_url + ')', 'background-size': '100% 100%' }" class="w-[24px] h-6 md:w-16 md:h-16 rounded-md cursor-pointer">
            </div>
            <BaseIcon v-else icon="menu" />
            <span class="text-xs font-bold">بیشتر</span>
          </div>
        </div>
      </div>
      <div class="absolute top-[1px] right-1/2 translate-x-1/2 w-full h-full">
        <img
          src="~@/assets/images/bg-mobile-menu.svg"
          class="w-full h-full object-cover drop-shadow-[0_0_12px_rgba(0,0,0,0.12)]"
        />
      </div>
    </div>
  </div>
  <MoreMenuBottomSheet v-model="moreItemsMenu" @log-out="emits('logOut')" />
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import MoreMenuBottomSheet from "./MoreMenuBottomSheet.vue";
import { ref, watch } from 'vue';

const props = defineProps({
    authStore: {
        type: Object,
        required: true
    }
})
const emits = defineEmits(['logOut'])
const moreItemsMenu = ref(false);
const route = useRoute();
const router = useRouter()
const activeLink = ref(route.path);
const changeRoute = (to: string) => {
  activeLink.value = to
  router.push(to)
}

// watch route path and assign to active link
watch(
  () => route.path,
  () => {
    if (activeLink.value !== route.path) {
      console.log(activeLink.value);
      activeLink.value = route.path
    }
  }
);
</script>

<style lang="scss" scoped>


</style>