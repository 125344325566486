<template>
  <v-bottom-sheet v-model="dialog" max-height="100%" min-height="10%" :fullscreen="fullHeight" :persistent="persistent">
    <div
      class="bg-white px-4 pb-3 pt-3 !rounded-t-[16px] h-full"
      :class="customClass"
    >
      <div class="h-1 w-8 bg-[#DADEE3] mx-auto rounded mb-4"></div>
      <slot />
    </div>
  </v-bottom-sheet>
</template>

<script lang="ts" setup>
import { computed } from "vue";
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  customClass: {
    type: String,
    default: "",
  },
  persistent: {
    type: Boolean,
    default: false
  },
  fullHeight: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const dialog = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss" scoped></style>
