<template>
  <BaseModal ref="modal" width="782" height="h-[440px]" title="پیش فاکتورها" icon="lab-profile" fill>
    <template #element="activatorProps">
      <div class="text-xs font-bold cursor-pointer hover:text-blue-500 text-blue-dark-500">
        <div
          v-bind="activatorProps"
          class="w-10 h-10 border-[1.3px] border-blue-dark-300 text-blue-dark-400 rounded-lg grid place-items-center cursor-pointer relative"
          @click="getInvoicesList"
        >
          <div v-if="userInfo.proforma_invoices_count > 0" class="absolute -top-2 -right-2">
            <span class="relative flex w-4 h-4">
              <span class="absolute inline-flex w-full h-full bg-blue-300 rounded-full opacity-75 animate-ping"></span>
              <span class="relative inline-flex w-4 h-4 bg-blue-400 rounded-full">
                <div class="mx-auto text-center text-white">
                  {{ getPreInvoiceCount }}
                </div>
              </span>
            </span>
          </div>
          <BaseIcon icon="lab-profile" class=" text-blue-dark-400" />
        </div>
      </div>
    </template>
    <template #header>
    </template>
    <template #body>
      <div v-if="invoiceList.length && !loading" class="px-6 pt-6">
        <template v-if="!smAndDown">
          <div v-for="invoice in invoiceList" :key="invoice" class="flex pb-5 mb-5 [&:not(:last-child)]:border-b">
            <div
              class="w-full md:w-[123px] h-[110px] relative flex items-end justify-between p-2.5 rounded-lg bg-gray-200"
            >
              <div class="w-[115px] h-[90px]">
                <v-img :src="invoice.product.image_url" :lazy-src="invoice.product.image_url" alt="" class="w-full h-[90px] object-contain">
                  <template v-slot:placeholder>
                    <div class="justify-center d-flex align-center fill-height">
                      <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </div>
              <img :src="invoice.factory_logo_url" alt="" class="w-[30px] h-[26px] object-cover" />
              <BaseIcon
                icon="favorite"
                class="inline-block md:hidden absolute top-2.5 left-2.5 !text-[32px] text-primary-500 cursor-pointer"
              />
            </div>
            <div class="flex flex-col justify-between w-full">
              <div class="flex items-center justify-between gap-2">
                <div class="flex items-center mr-3 text-sm font-bold">
                  <BaseIcon icon="category" />
                  <div class="mr-2">
                    <span class="font-bold text-blue-dark-500">
                      {{ invoice.product.title }} - 
                    </span>
                    {{ String(parseFloat(invoice.tonage)) }} تن
                  </div>
                </div>
                <div class="text-sm text-blue-dark-100">
                  <span class="ml-2">شماره</span>
                  {{ invoice.id }}
                </div>
              </div>
              <div class="mr-3 text-blue-dark-300">
                <span class="text-sm ">
                  <BaseIcon icon="universal-currency" class="text-xl align-middle" />
                  مبلغ فاکتور
                </span>
                <span class="mr-2 text-xs text-blue-dark-500">                 
                  {{ new Intl.NumberFormat().format(invoice.tonage * invoice.price_per_ton) }} ریال
                </span>
              </div>
              <div class="mr-3 text-blue-dark-300">
                <div class="flex justify-between">
                  <div class="text-sm ">
                    <div v-if="invoice.status != 1">
                      <BaseIcon icon="info" class="text-xl align-middle text-secondry-600" fill />
                      به دلیل تغییر قیمت این پیش فاکتور دیگر اعتبار ندارد.
                    </div>
                    <div v-else>
                      <BaseIcon icon="event" class="text-xl align-top text-blue-dark-300" />
                      <div class="inline-block mr-1 d-ltr">
                        {{ dayjs(invoice.date_paid || invoice.created_at).calendar("jalali").locale("fa").format("YYYY/MM/DD HH:MM") }}
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="text-sm font-bold cursor-pointer text-primary-500" @click="removeInvoiceHandler(invoice)">
                      حذف
                    </div>
                    <div v-if="invoice.status == 1" class="pr-4 mr-4 text-sm font-bold border-r cursor-pointer text-primary-500">
                      <BaseLoading v-if="downloadLoading && selectedInvoice == invoice.id" />
                      <div v-else @click="downloadPreInvoce(invoice.id)">
                        دانلود
                      </div>
                    </div>
                    <div v-if="invoice.status == 1" class="pr-4 mr-4 text-sm font-bold border-r cursor-pointer text-primary-500" @click="modal.closeDialog()">
                      <router-link :to="`/store/confirm/${invoice.id}`">
                        ادامه خرید
                        <BaseIcon icon="arrow-circle-left" class="text-2xl align-top text-primary-500" />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="invoice in invoiceList" :key="invoice" class="pb-6 [&:not(:last-child)]:mb-6 [&:not(:last-child)]:border-b">
            <div
              class="w-full md:w-[123px] h-[110px] relative flex items-end justify-between p-2.5 rounded-lg bg-gray-200"
            >
              <div class="w-[115px] h-[90px]">
                <img :src="invoice.product.image_url" alt="" class="w-full h-[90px] object-contain" />
              </div>
              <img :src="invoice.factory_logo_url" alt="" class="w-[30px] h-[26px] object-cover" />
            </div>
            <div class="py-3">
              <div class="flex items-center">
                <BaseIcon icon="category" class="text-xl align-middle text-blue-dark-500" />
                <div class="mr-2 font-bold">
                  <span class="font-bold text-blue-dark-500">
                    {{ invoice.product.title }} -
                  </span>
                  <span>
                    {{ String(parseFloat(invoice.tonage)) }} تن
                  </span>
                </div>
              </div>
              <div class="mt-3">
                <span class="text-xs text-blue-dark-200"> پیش فاکتور {{ invoice.id }}</span>
              </div>
              <div class="mt-2">
                <div class="mt-1 text-sm text-blue-dark-500">
                  <BaseIcon icon="universal-currency" class="ml-2 text-xl align-middle" />
                  {{ new Intl.NumberFormat().format(invoice.tonage * invoice.price_per_ton) }} ریال
                </div>
              </div>
              <div v-if="invoice.status != 1" class="mt-3 text-sm">
                <BaseIcon icon="info" class="text-xl align-middle text-secondry-600" fill />
                <span class="mr-2 text-xs text-blue-dark-300">به دلیل تغییر قیمت این پیش فاکتور دیگر اعتبار ندارد.</span>
              </div>
              <div v-else class="w-full mt-4 text-xs text-right text-blue-dark-500 d-ltr">
                {{ dayjs(invoice.date_paid || invoice.created_at).calendar("jalali").locale("fa").format("YYYY/MM/DD HH:MM") }}
              </div>
              <div class="flex justify-between mt-6" :class="invoice.status == 1 ? 'justify-between' : 'justify-end'">
                <div class="text-sm font-bold cursor-pointer text-primary-500" @click="removeInvoiceHandler(invoice)">
                  <BaseIcon icon="trash" class="ml-2 text-xl align-middle text-primary-500" />
                  حذف
                </div>
                <div v-if="invoice.status == 1" class="w-[1px] bg-blue-dark-50"></div>
                <div v-if="invoice.status == 1" class="text-sm font-bold cursor-pointer text-primary-500">
                  <BaseLoading v-if="downloadLoading && selectedInvoice == invoice.id" />
                  <div v-else @click="downloadPreInvoce(invoice.id)">
                    <BaseIcon icon="download-offline" class="ml-2 text-xl align-middle text-primary-500" />
                    دانلود
                  </div>
                </div>
                <div v-if="invoice.status == 1" class="w-[1px] bg-blue-dark-50"></div>
                <div v-if="invoice.status == 1" class="pr-2 mr-2 text-sm font-bold cursor-pointer text-primary-500" @click="modal.closeDialog()">
                  <router-link :to="`/store/confirm/${invoice.id}`">
                    ادامه خرید
                    <BaseIcon icon="arrow-circle-left" class="text-xl align-middle text-primary-500" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <EmptyBox v-else title="فاکتوری به ثبت نرسیده است" :loading="loading" />
    </template>
    <template #footer="isActive">
      <div class="flex flex-row-reverse items-center justify-between p-6">
        <v-btn class="border hover:bg-blue-500 border-primary-500 hover:text-white text-primary-500" @click="isActive.value = false">
          <span class="text-sm">
            بستن
          </span>
        </v-btn>
        <div class="text-sm text-red-600 cursor-pointer" @click="removeAllHandler">
          <BaseIcon icon="trash" class="ml-2 align-middle" />
          حذف تمام موارد
        </div>
      </div>
    </template>
  </BaseModal>
  <v-dialog
    v-model="removeInvoice"
    width="auto"
  >
    <div class="p-4 text-center bg-white rounded-lg w-72">
      <BaseIcon icon="warning" class="text-red-100 text-[40px] mb-2" />
      <div class="text-sm font-bold text-blue-dark-500">
        فاکتور حذف شده قابل بازگردانی نمی باشد
      </div>
      <div class="border-t-[1px] my-3"></div>
      <div class="mr-4 text-xs text-right text-blue-dark-300">
        با حذف این پیش فاکتور تمامی اطلاعات این پیش فاکتور حذف خواهد شد .
      </div>
      <div class="flex gap-2 mt-8">
        <v-btn class="w-1/2 border text-primary-500 hover:text-white hover:bg-blue-500 border-primary-500" :loading="loading" @click="removeInvoice = false">
          <span class="text-sm">
            انصراف
          </span>
        </v-btn>
        <v-btn class="w-1/2 text-white bg-blue-500 border border-primary-500" :loading="loading" @click="removeSelectedInvoice">
          <BaseIcon icon="check-circle" class="ml-2" />
          <span class="text-sm">
            تایید
          </span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script lang="ts" setup>
import { apiDownloadPreInvoice, apiGetPreInvoicesList, apiRemovePreInvoice } from '@/services/store/store';
import BaseModal from '../base/BaseModal.vue';
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import EmptyBox from "@/components/base/BaseEmptyData.vue";
import { useDisplay } from 'vuetify';
import { toast, type ToastOptions } from "vue3-toastify";
import dayjs from "dayjs";
import { useAuthStore } from '@/stores/authStore';
import { apiGetUserInfo } from '@/services/authentication/authentication';
import BaseLoading from "@/components/base/BaseLoading.vue";
import { saveAs } from 'file-saver';

const userInfo = useAuthStore().userInfo
const { smAndDown, platform } = useDisplay()
const invoiceList: any = ref([])
const router = useRouter()
const modal = ref()
const loading = ref(false)
const removeInvoice = ref(false)
const selectedInvoice = ref()
const downloadLoading = ref()
const getInvoicesList = async () => {
  loading.value = true
  const { data } = await apiGetPreInvoicesList()
  const list: any = data
  invoiceList.value = list.data
  loading.value = false
}
const removeInvoiceHandler = async (invoice: any) => {
  if (invoice.status != 1) {
    selectedInvoice.value = invoice.id
    removeSelectedInvoice()
    return
  }
  removeInvoice.value = true
  selectedInvoice.value = invoice.id
}
const removeSelectedInvoice = async () => {
  loading.value = true
  try {
    const body = {
      id: selectedInvoice.value
    }
    const data = await apiRemovePreInvoice(body)
    toast(data.data.message, {
      type: "success",
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
    loading.value = false
    getInvoicesList()
    useAuthStore().userInfo = (await apiGetUserInfo()).data.data;
  } catch (error) {
    loading.value = false
    console.log(error)
  } finally {
    removeInvoice.value = false
  }
}
const removeAllHandler = async () => {
  loading.value = true
  try {
    const body = {
      all: 1
    }
    const data = await apiRemovePreInvoice(body)
    toast(data.data.message, {
      type: "success",
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
    loading.value = false
    userInfo.proforma_invoices_count = 0
    getInvoicesList()
  } catch (error) {
    loading.value = false
    console.log(error)
  } finally {
    removeInvoice.value = false
  }
}
/**
 * Computes the count of pre-invoices.
 *
 * @returns {number} The count of pre-invoices.
 */
const getPreInvoiceCount = computed(() => {
  return  useAuthStore().userInfo.proforma_invoices_count
})
/**
 * Downloads a pre-invoice with the specified ID.
 *
 * @param {any} id - The ID of the pre-invoice to download.
 * @returns {Promise<void>} - A promise that resolves when the pre-invoice is downloaded.
 */
const downloadPreInvoce = async (id: any) => {
  downloadLoading.value = true
  selectedInvoice.value = id
  try {
    const { data } = await apiDownloadPreInvoice(id)
    if (!smAndDown.value) {      
      const link = document.createElement('a')
      link.href = data.path
      link.download = data.origin_name
      link.target = '_blank'
      link.click()
      window.URL.revokeObjectURL(data.path)
    } else if (platform.value.ios) {
      window.location.replace(data.path)
    } else {
      saveAs(data.path, data.origin_name)
    }
  } catch (error) {
    console.log(error); 
  } finally {
    downloadLoading.value = false
  }
}
</script>